import React, { useState, useEffect } from 'react';

import '../../App.css';
import ServiceSEO from '../../images/service_seo.png';
import ServiceUX from '../../images/service_ux.png';
import ServiceMaintenance from '../../images/service_maintenance.png';
import ServiceAnalytics from '../../images/service_analytics.png';
import ServiceHosting from '../../images/service_hosting.png';
import ServiceWeb from '../../images/service_web.png';
import {
  Link
} from "react-router-dom";
import Header from '../../app/components/Header';
import SquareLogo from '../../images/LOGO_SQUARE1.2.3Dai.jpg';
import { BiWorld } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import { AiTwotoneMail } from "react-icons/ai";
import axios from 'axios';

interface Props {
  reportView?: Function;
}

const RegisterPage: React.FC<Props> = ({ reportView }) => {

  const [formValues, setFormValues] = useState({email: '', password: '', confirmPassword: ''});
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSubmit = (ev: any) => {
    const url = "/api/register";
    setSubmitting(true);
    axios.post(url, formValues).then(() => {
      setSubmitting(false);
      setSubmitSuccess(true);
    });
    ev.preventDefault();
  };

  const handleInputChange = (ev: any) => {
    const target = ev.target;
    const value = target.value;
    const name = target.name;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  useEffect(() => {
    reportView && reportView(window.location.pathname);
  }, [window.location.pathname])

  return (
    <div className="App">
      <Header />
      <div className="register-page">
        <div className="register-form">
          <h1>Register your account</h1>
          <p className="subtitle">Already signed up? <Link to="/login">Login</Link></p>
          {submitSuccess ? (
              <p>Thank you for your message!</p>
            ) : (
            <form onSubmit={handleSubmit}>
              <p>Name<span className="red">*</span></p>
              <p className="input-block">
                <input type="text" name="email" value={formValues.email} onChange={handleInputChange} />
              </p>
              <p>Password<span className="red">*</span></p>
              <p className="input-block">
                <input type="password" name="password" value={formValues.password} onChange={handleInputChange} />
              </p>
              <p>Confirm Password<span className="red">*</span></p>
              <p className="input-block">
                <input type="password" name="confirmPassword" value={formValues.confirmPassword} onChange={handleInputChange} />
              </p>
              <p><button type="submit">Send{submitting && <VscLoading className="rotating" style={{ marginLeft: '10px' }}/>}</button></p>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
