import React from 'react';

import '../../../App.css';
import WebDev from '../../../images/services/WebDevelopment.jpg';
import ServicePage from '../../../app/components/ServicePage';
import Header from '../../../app/components/Header';

interface Props {
  reportView?: Function;
}

const WebDevelopmentServicePage: React.FC<Props> = ({ reportView }) => {
  return (
    <div className="App">
      <Header />
      <ServicePage reportView={reportView} imgSrc={WebDev} 
        body={
          <>
            {'Our team has over 10 years experience with web development at top computer science programs and tech companies. We can help you '}
            <strong>
              {'build your project with the most up-to-date software and the latest features'}
            </strong>
            {', to make your website stand out, fast, and secure. It will impress your customers and make them want to come back to visit your site again and again.'}
          </>
        }
        title="Web Development" 
      />
    </div>
  );
};

export default WebDevelopmentServicePage;
