import React from 'react';

import '../../../App.css';
import idea from '../../../images/idea2.jpg';
import Seo from '../../../images/services/SEO.jpg';
import ServicePage from '../../../app/components/ServicePage';
import Header from '../../../app/components/Header';

interface Props {
  reportView?: Function;
}

const SeoServicePage: React.FC<Props> = ({ reportView }) => {
  return (
    <div className="App">
      <Header />
      <ServicePage 
        reportView={reportView}
        imgSrc={Seo} 
        imgMaxWidth={500}
        body={(
          <>
            <strong>{'Want to increase your organic web traffic and get your website in front of more potential customers?'}</strong>
            {' We can give you the tools to set yourself apart from your competition. '}
            {'List your business on Google and other search engines, and use keywords and content marketing to attract even more of your target users.'}
          </>
        )} 
        title="Search Engine Optimization" 
      />
    </div>
  );
};

export default SeoServicePage;
