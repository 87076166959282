import React from 'react';

import '../../../App.css';
import WebMaintenance from '../../../images/services/Maintenance.jpg';
import ServicePage from '../../../app/components/ServicePage';
import Header from '../../../app/components/Header';

interface Props {
  reportView?: Function;
}

const WebMaintenanceServicePage: React.FC<Props> = ({ reportView }) => {
  return (
    <div className="App">
      <Header />
      <ServicePage reportView={reportView} imgSrc={WebMaintenance} body={
          <>
            <strong>
              {'When things break or you need to update your website, you want to know a service like BOS-BKK Web Marketing has your back.'}
            </strong>
            {' We offer flexible maintenance plans that will give you peace of mind that your website is reliable, with updated software and updated content. The best websites are not finished all at once, but change in response to customer feedback data and the changing needs of your business. '}
            <br />
            <strong>
              {'The more up-to-date your website is, the more relevant it will be for your customers.'}
            </strong>
            {' Let us help you maximize your site\'s return on investment.'}
          </>
        } 
        title="Web Maintenance" 
      />
    </div>
  );
};

export default WebMaintenanceServicePage;
