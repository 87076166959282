import React, { useState } from 'react';

import '../../App.css';
import {
  Link,
  Redirect,
} from "react-router-dom";
import Header from '../../app/components/Header';
import AdminInfo from '../../app/components/AdminInfo';
import axios from 'axios';

const AdminPage: React.FC = () => {
  const [auth, setAuth] = useState(null);
  const [email, setEmail] = useState('');
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const waitForAuth = () => {
    setLoading(true);
    const url = "/api/session";
    axios.get(url).then((data) => {
      setLoading(false);
      setEmail(data && data.data && data.data.email);
      setAuth(data && data.data && data.data.loggedIn);
      setIsAdminUser(data && data.data && data.data.isAdminUser);
    });
  };

  auth === null && !loading && waitForAuth();
  if (loading) return null;
  return auth && isAdminUser ? (
    <div className="App">
      <Header />
      <div className="admin-page">
        <p className="subtitle">You are logged in as <strong>{email}</strong>. <a href="/logout">Logout</a></p>
        <AdminInfo />
        <p style={{ marginTop: '16px' }}>
          <button className="button">Add a customer</button>
          <button className="button">Manage projects</button>
        </p>
      </div>
    </div>
  ) : <Redirect to="/" />;
};

export default AdminPage;
