import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import '../../App.css';
import Button from '@material-ui/core/Button';
import RatingIcon from '../../images/icon_rating.png';
import ShopIcon from '../../images/icon_shop.png';
import DesignIcon from '../../images/icon_design.png';
import BasketIcon from '../../images/icon_basket.png';
import AffiliateIcon from '../../images/icon_affiliate.png';
import ChatIcon from '../../images/icon_chat.png';
import ArrowLeft from '../../images/Arrow-01.png';
import ArrowRight from '../../images/Arrow-03.png';
import ArrowDown from '../../images/Arrow-02.png';

const Infographic: React.FC = () => {
  const history = useHistory();
  const handleOnClick = useCallback(() => history.push('/contact-us'), [history]);
  const handleLearnMore = useCallback(() => history.push('/services'), [history]);
  return (
    <div className="Infographic">
      <div className="info-title">
        <div className="info-title-column">
          <h2 className="info-title-2">CREATE</h2>
          <h3 className="info-title-3">YOUR WEBSITE</h3>
          <div className="info-title-accent-container">
            <h3 className="info-title-accent">with us</h3>
          </div>
        </div>
        <h1 className="info-title-1">
          HOW TO
        </h1>
        <div className="info-title-btn-wrapper">
          <Button variant="contained" disableElevation color="secondary" onClick={handleLearnMore} style={{backgroundColor: '#499ce0', borderRadius: '16px', padding: '4px 24px', marginRight: '10px', textTransform: 'none', fontWeight: 600 }}>
            LEARN MORE
          </Button>
          <Button variant="contained" disableElevation color="secondary" style={{backgroundColor: '#000', borderRadius: '16px', padding: '4px 24px', textTransform: 'none', fontWeight: 600}} onClick={handleOnClick}>
            GET STARTED
          </Button>
        </div>
      </div>

      <div className="info-steps">
        <div className="info-step-row">
          <div className="info-step column">
            <img src={ChatIcon} width="140px" height="140px" />
            <p style={{ marginTop: '-36px', alignSelf: 'flex-end', width: '100px' }}>
              <div className="number" style={{ marginLeft: '-28px', lineHeight: '18px' }}>1</div>
              Tell us about your project.
            </p>
          </div>
          <div className="arrow">
            <img src={ArrowRight} width="60px" height="60px" />
          </div>
          <div className="info-step column">
            <img src={AffiliateIcon} width="140px" height="140px" />
            <p style={{ marginTop: '-12px', marginRight: '-28px' }}>
              <div className="number" style={{ marginLeft: '-28px', lineHeight: '18px' }}>2</div>
    Our UX/UI design team creates <br />
    your custom mockup. 
            </p>
          </div>
          <div className="arrow">
            <img src={ArrowRight} width="60px" height="60px" />
          </div>
          <div className="info-step-wrapper">
            <div className="info-step reverse">
              <img src={DesignIcon} width="140px" height="140px" />
              <p>
                <div className="number" style={{ marginTop: '-28px', lineHeight: '18px' }}>3</div>
                We build you a prototype of your final project.
              </p>
            </div>
            <div className="arrow" style={{ paddingBottom: '0px' }}>
              <img src={ArrowDown} height="60px" />
            </div>
          </div>
        </div>
        <div className="info-step-row" style={{ marginTop: '-12px' }}>
          <div className="info-step">
            <img src={BasketIcon} width="140px" height="140px" />
            <p>
              <div className="number" style={{ marginTop: '-28px', lineHeight: '18px' }}>6</div>
              Website maintenance,
    general updates, and
    adding new features
    to keep your website
    professional and
    functional at all time.
            </p>
          </div>
          <div className="arrow">
            <img src={ArrowLeft} width="60px" height="60px" />
          </div>
          <div className="info-step column">
            <img src={ShopIcon} width="200px" height="200px" />
            <p style={{ marginRight: '-28px', marginTop: '4px' }} className="info-step-5">
              <div className="number" style={{ marginLeft: '-28px', lineHeight: '18px' }}>5</div>
              Test and launch your custom well-designed website.
            </p>
          </div>
          <div className="arrow">
            <img src={ArrowLeft} width="60px" height="60px" />
          </div>
          <div className="info-step">
            <img src={RatingIcon} width="140px" height="140px" />
            <p>
              <div className="number" style={{ marginTop: '-28px', lineHeight: '18px' }}>4</div>
              Our web development
    team builds your website
    with up-to-date features
    and latest web technologies.
            </p>
          </div>
        </div>
        <div className="info-title-btn-wrapper-2">
          <Button variant="contained" disableElevation color="secondary" onClick={handleLearnMore} style={{backgroundColor: '#499ce0', borderRadius: '16px', padding: '4px 24px', marginRight: '10px', textTransform: 'none', fontWeight: 600 }}>
            LEARN MORE
          </Button>
          <Button variant="contained" disableElevation color="secondary" style={{backgroundColor: '#000', borderRadius: '16px', padding: '4px 24px', textTransform: 'none', fontWeight: 600}} onClick={handleOnClick}>
            GET STARTED
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Infographic;
