import React, { useCallback, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import SEO from './SEO';
import Button from '@material-ui/core/Button';

import '../../App.css';

interface Props {
  title?: string;
  body?: string | JSX.Element;
  imgSrc?: string;
  imgMaxWidth?: number;
  reportView?: Function;
}

const ServicePage: React.FC<Props> = ({title, body, imgSrc, imgMaxWidth, reportView}) => {
  const history = useHistory();
  const handleOnClick = useCallback(() => history.push('/services'), [history]);
  const handleGetQuote = useCallback(() => history.push('/contact-us'), [history]);
  useEffect(() => {
    reportView && reportView(window.location.pathname);
  }, [window.location.pathname, reportView]);
  return (
    <div className="service-page">
      <SEO />
      <img src={imgSrc} style={{ maxWidth: imgMaxWidth && `${imgMaxWidth}px` }} />
      <div className="service-content">
        <h1 className="services-title">
          OUR SERVICES
        </h1>
        <h2>{title}</h2>
        <p>{body}</p>
        <div className="service-buttons">
          <Button variant="contained" disableElevation color="secondary" onClick={handleOnClick} style={{backgroundColor: '#499ce0', borderRadius: '16px', padding: '4px 24px', marginRight: '10px', textTransform: 'none', fontWeight: 600 }}>
            MORE SERVICES
          </Button>
          <Button variant="contained" disableElevation color="secondary" onClick={handleGetQuote} style={{backgroundColor: '#000', borderRadius: '16px', padding: '4px 24px', textTransform: 'none', fontWeight: 600 }}>
            GET A QUOTE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
