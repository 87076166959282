import React from 'react';

import '../../../App.css';
import idea from '../../../images/idea2.jpg';
import WebHosting from '../../../images/services/WebHosting.jpg';
import ServicePage from '../../../app/components/ServicePage';
import Header from '../../../app/components/Header';

interface Props {
  reportView?: Function;
}

const WebHostingServicePage: React.FC<Props> = ({ reportView }) => {
  return (
    <div className="App">
      <Header />
      <ServicePage reportView={reportView} imgSrc={WebHosting} body={
        <>
          {'Launch your website on the World Wide Web, accessible by all your clients, friends and family. We will handle hosting your software and content on a leading cloud provider to ensure maximum uptime, reliability and speed. Our hosting plans are flexible and cost-effective for any size business.'}
        </>
      } title="Web Hosting" />
    </div>
  );
};

export default WebHostingServicePage;
