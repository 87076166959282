import React, { useEffect } from 'react';

import '../../App.css';
import ServiceSEO from '../../images/service_seo.png';
import ServiceUX from '../../images/service_ux.png';
import ServiceMaintenance from '../../images/service_maintenance.png';
import ServiceAnalytics from '../../images/service_analytics.png';
import ServiceHosting from '../../images/service_hosting.png';
import ServiceWeb from '../../images/service_web.png';
import {
  Link
} from "react-router-dom";
import SquareLogo from '../../images/LOGO_SQUARE1.2.3Dai.jpg';
import { BiWorld } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";
import { AiTwotoneMail } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

import Header from '../../app/components/Header';
import SEO from '../../app/components/SEO';
import PackagesComponent from '../../app/components/PackagesComponent';
import { useHistory } from "react-router-dom";

interface Props {
  reportView: Function;
}

const PackagesPage: React.FC<Props> = ({ reportView }) => {
  const history = useHistory();

  const handleGetStarted = () => {
    history.push('/contact-us');
  };

  useEffect(() => {
    reportView(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className="App">
      <Header />

      <div className="packages-page">
        <div className="packages-header">
          <h1>PACKAGES</h1>
          <p>Choose a plan that fits with your business style or get a free consultation to custom build your website.</p>
        </div>
        <div className="packages-component">
          <div className="package-column">
            <h2>One Page</h2>
            <h3>$990</h3>
            <p>
              <FaCheck size={20} />
              Domain name
            </p>
            <p>
              <FaCheck size={20} />
              One year hosting
            </p>
            <p>
              <FaCheck size={20} />
              Custom web design 
            </p>
            <p>
              <FaCheck size={20} />
              1 Page
            </p>
            <p>
              <FaCheck size={20} />
              Standard SSL
            </p>
            <p>
              <FaCheck size={20} />
              Contact form
            </p>
            <p>
              <FaCheck size={20} />
              Email forwarding
            </p>
            <p>
              <FaCheck size={20} />
              Mobile and tablet friendly 
            </p>
            <div className="get-started-wrapper">
              <div className="get-started" onClick={handleGetStarted}>
                Get Started
              </div>
            </div>
          </div>
          <div className="package-column">
            <h2>Standard</h2>
            <h3>$1290</h3>
            <p>
              <FaCheck size={20} />
              Domain name
            </p>
            <p>
              <FaCheck size={20} />
              One year hosting
            </p>
            <p>
              <FaCheck size={20} />
              Custom web design 
            </p>
            <p>
              <FaCheck size={20} />
              Up to 5 Pages
            </p>
            <p>
              <FaCheck size={20} />
              Standard SSL
            </p>
            <p>
              <FaCheck size={20} />
              Contact form
            </p>
            <p>
              <FaCheck size={20} />
              Email forwarding
            </p>
            <p>
              <FaCheck size={20} />
              Minor content updates
            </p>
            <p>
              <FaCheck size={20} />
              Mobile and tablet friendly 
            </p>
            <div className="get-started-wrapper">
              <div className="get-started" onClick={handleGetStarted}>
                Get Started
              </div>
            </div>
          </div>
          <div className="package-column">
            <h2>Premium</h2>
            <h3>$1590</h3>
            <p>
              <FaCheck size={20} />
              Domain name
            </p>
            <p>
              <FaCheck size={20} />
              One year hosting
            </p>
            <p>
              <FaCheck size={20} />
              Custom web design 
            </p>
            <p>
              <FaCheck size={20} />
              Up to 10 Pages
            </p>
            <p>
              <FaCheck size={20} />
              Standard SSL
            </p>
            <p>
              <FaCheck size={20} />
              Contact form
            </p>
            <p>
              <FaCheck size={20} />
              Email forwarding
            </p>
            <p>
              <FaCheck size={20} />
              Minor content updates
            </p>
            <p>
              <FaCheck size={20} />
              Google Analytics 
            </p>
            <p>
              <FaCheck size={20} />
              Built-in SEO
            </p>
            <p>
              <FaCheck size={20} />
              Mobile and tablet friendly 
            </p>
            <div className="get-started-wrapper">
              <div className="get-started" onClick={handleGetStarted}>
                Get Started
              </div>
            </div>
          </div>
          <div className="package-column">
            <h2>E-Commerce</h2>
            <h3>$1990</h3>
            <p>
              <FaCheck size={20} />
              Domain name
            </p>
            <p>
              <FaCheck size={20} />
              One year hosting
            </p>
            <p>
              <FaCheck size={20} />
              Custom web design 
            </p>
            <p>
              <FaCheck size={20} />
              Up to 10 Products 
            </p>
            <p>
              <FaCheck size={20} />
              Standard SSL
            </p>
            <p>
              <FaCheck size={20} />
              Contact form
            </p>
            <p>
              <FaCheck size={20} />
              Email forwarding
            </p>
            <p>
              <FaCheck size={20} />
              Minor content updates
            </p>
            <p>
              <FaCheck size={20} />
              Google Analytics 
            </p>
            <p>
              <FaCheck size={20} />
              Built-in SEO
            </p>
            <p>
              <FaCheck size={20} />
              Mobile and tablet friendly 
            </p>
            <div className="get-started-wrapper">
              <div className="get-started" onClick={handleGetStarted}>
                Get Started
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagesPage;
