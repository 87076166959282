import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import '../../App.css';
import logo from '../../images/LOGO_LINE.ai1.2.3.4.png';
import logoText from '../../images/LOGO_LINE.ai1.2.3.4_TEXT.png';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { BsX } from 'react-icons/bs';

interface Props {
  setScrollPage?: Function;
}

const Header: React.FC<Props> = ({setScrollPage}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();
  return (
    <div className="header">
      <div className="header-content">
        <Link to="/">
          <div onClick={() => { setScrollPage && setScrollPage(0); }}><img src={logo} width={130} /></div>
        </Link>
        <div className="header-left">
          <div className="header-sidebar-btn" onClick={() => setSidebarOpen(true) }>
            <FaBars size={20}  />
          </div>
          <a href="/contact-us">
            GET STARTED
          </a>
        </div>
      </div>
      {sidebarOpen && (
        <div className="sidebar">
          <div className="sidebar-close-btn" onClick={() => setSidebarOpen(false) }>
            <BsX size={32} />
            <img src={logoText} width="120px" height="52px" />
            <div style={{ width: '32px', height: '32px', display: 'block' }}>&nbsp;</div>
          </div>

          <div className="menu-items">
            <Link style={{ textDecoration: 'none' }}>
              <div className="menu-item" onClick={() => {
                setSidebarOpen(false);
                setScrollPage && setScrollPage(0);
                history.push('/');
              }}>
                Home
              </div>
            </Link>
            <Link style={{ textDecoration: 'none' }}>
              <div className="menu-item" onClick={() => { 
                setSidebarOpen(false);
                setScrollPage && setScrollPage(3);
                history.push('/who-we-are');
              }}>
                Who We Are
              </div>
            </Link>
            <Link to="/infographic" style={{ textDecoration: 'none' }}>
              <div className="menu-item">
                How We Work
              </div>
            </Link>
            <Link to="/services" style={{ textDecoration: 'none' }}>
              <div className="menu-item">
                Our Services
              </div>
            </Link>
            <Link to="/packages" style={{ textDecoration: 'none' }}>
              <div className="menu-item">
                Packages
              </div>
            </Link>
            <Link to="/contact-us" style={{ textDecoration: 'none' }}>
              <div className="menu-item">
                Contact Us
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
