import React, { useEffect } from 'react';

import '../../App.css';
import Infographic from '../components/Infographic';
import Header from '../components/Header';
import SEO from '../components/SEO';

interface Props {
  reportView?: Function;
}

const InfographicPage: React.FC<Props> = ({ reportView }) => {
  useEffect(() => {
    reportView && reportView(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <div className="infographic-page">
      <SEO />
      <Header />
      <Infographic /> 
    </div>
  );
};

export default InfographicPage;
