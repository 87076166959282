import React from 'react';

import '../../../App.css';
import UxUi from '../../../images/services/UX_UI.jpg';
import ServicePage from '../../../app/components/ServicePage';
import Header from '../../../app/components/Header';

interface Props {
  reportView?: Function;
}

const UxUiServicePage: React.FC<Props> = ({ reportView }) => {
  return (
    <div className="App">
      <Header />
      <ServicePage reportView={reportView} imgSrc={UxUi} body={(
        <>
          {'One of the top things we think about when building your project is the '}<strong>{'UX/UI Design, which is the user experience and user interface.'}</strong>{' We go beyond a generic website template and think about your target customers and how to facilitate easy customer interactions. '}<strong>{'Professional and attractive design creates good customer impressions with your brand.'}</strong>{' When starting your project, our UX/UI design team will work with you to create a custom mockup that meets your target customers\' needs. We\'ll make sure it\'s easy to use and looks professional.'}
          <br />
          <strong>{'It\'s worth it to get started now!'}</strong>
        </>
        )} 
        title="UX/UI Design" />
    </div>
  );
};

export default UxUiServicePage;
