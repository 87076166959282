import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '../../images/square_icon.png';

const defaultSEO = {
  title: 'BOS-BKK Web Marketing',
  description: 'BOS-BKK is an interactive and international web marketing agency. With more than 10 years experience in the web design and software development fields, we help our clients to create unique well-designed websites that fit your budget. We offer digital marketing to our clients to help you grow your sales and achieve your business goals. We are here to help.',
  image: '',
  siteName: 'BOS-BKK Web Marketing',
  url: 'https://bos-bkk.com',
};

const SEO: React.FC = ({}) => (
  <Helmet
    titleTemplate="%s"
    defaultTitle={defaultSEO.title}
    defer={true}
  >
    <title>{defaultSEO.title}</title>
    <meta name="description" content={defaultSEO.description} />
    <meta property="og:title" content={defaultSEO.title} />
    <meta property="og:site_name" content={defaultSEO.title} />
    <meta
      property="og:description"
      content={defaultSEO.description}
    />
    <meta property="og:image" content={defaultSEO.image} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:url" content={`${defaultSEO.url}`} />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en_US" />

    <meta name="twitter:title" content={defaultSEO.title} />
    <meta
      name="twitter:description"
      content={defaultSEO.description}
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image:alt" content={defaultSEO.title} />

    <link
      rel="shortcut icon"
      type="image/x-icon"
      href={favicon}
    />
  </Helmet>
);

export default SEO;
