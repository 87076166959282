import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import axios from 'axios';

import '../../App.css';

const AdminInfo: React.FC = () => {
  const [adminInfo, setAdminInfo] = useState({userCount: null, customers: []});
  const fetchInfo = () => {
    const url = "/api/admin/info";
    axios.get(url).then((data) => {
      console.log(data);
      setAdminInfo(data && data.data && data.data.adminInfo);
    });
  };
  const renderCustomers = () => {
    return adminInfo.customers.map((customer: any) => (
      <div className="customer">
        <h3>{customer.name} - Customer (id: {customer.id})</h3>

        {customer.projects.length > 0 && (
          <>
            <h4>Projects</h4>
            <ul>
              {customer.projects.map((project) => (
                <li>
                  <strong>{project.name}</strong> - {project.description} - <a href={project.url}>{project.url}</a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    ));
  };
  useEffect(() => {
    fetchInfo();
  }, []);
  return (
    <div className="admin-info">
      <p>User Count: {adminInfo.userCount}</p>
      <h2 style={{ marginTop: '48px', marginBottom: '8px' }}>Customers</h2>
      {renderCustomers()}
    </div>
  );
};

export default AdminInfo;
