import React from 'react';

import '../../../App.css';
import Analytics from '../../../images/services/GoogleAnalytics.jpg';
import ServicePage from '../../../app/components/ServicePage';
import Header from '../../../app/components/Header';

interface Props {
  reportView?: Function;
}

const AnalyticsServicePage: React.FC<Props> = ({ reportView }) => {
  return (
    <div className="App">
      <Header />
      <ServicePage reportView={reportView} imgSrc={Analytics} body={
          <>
            <strong>{'To understand your clients,'}</strong>
            {' where they are from, and what they are looking for, we need to plug in tracking tools such as Google Analytics. This will allow you to track important metrics like session duration, bounce rate, and pages-per-session. These tools will let you inform your business decisions with real-world data. '}
            <strong>{'Learn about your market to better target your advertising and increase your competitive edge.'}</strong>
          </>
        } title="Product Analytics" />
    </div>
  );
};

export default AnalyticsServicePage;
