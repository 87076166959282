import React, { useState, useEffect } from 'react';

import '../../App.css';
import ServiceSEO from '../../images/service_seo.png';
import ServiceUX from '../../images/service_ux.png';
import ServiceMaintenance from '../../images/service_maintenance.png';
import ServiceAnalytics from '../../images/service_analytics.png';
import ServiceHosting from '../../images/service_hosting.png';
import ServiceWeb from '../../images/service_web.png';
import {
  Link,
} from "react-router-dom";
import Header from '../../app/components/Header';
import SEO from '../../app/components/SEO';
import SquareLogo from '../../images/LOGO_SQUARE1.2.3Dai.jpg';
import { BiWorld } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import { AiTwotoneMail } from "react-icons/ai";
import axios from 'axios';

interface Props {
  reportView?: Function;
}

const ContactUsPage: React.FC<Props> = ({ reportView }) => {
  const [formValues, setFormValues] = useState({full_name: '', email: '', message: ''});
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSubmit = (ev: any) => {
    const url = "/api/contact";
    setSubmitting(true);
    axios.post(url, formValues).then(() => {
      setSubmitting(false);
      setSubmitSuccess(true);
    });
    ev.preventDefault();
  };

  const handleInputChange = (ev: any) => {
    const target = ev.target;
    const value = target.value;
    const name = target.name;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  useEffect(() => {
    reportView && reportView(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className="App">
      <SEO />
      <Header />
      <div className="contact-us-page">
        <div className="contact-info">
          <img src={SquareLogo} width="240px" />
          <h1>Contact Us</h1>
          <div className="contact-infos">
            <div className="contact-row">
              <div className="contact-icon">
                <AiTwotoneMail size={20} />
              </div>
              <div className="contact-text">
                support@bos-bkk.com
              </div>
            </div>
            <div className="contact-row">
              <div className="contact-icon">
                <FaPhone size={20} />
              </div>
              <div className="contact-text">
                EN 781-799-4205<br />
                TH 617-513-6336
              </div>
            </div>
            <div className="contact-row">
              <div className="contact-icon">
                <BiWorld size={20} />
              </div>
              <div className="contact-text">
                bos-bkk.com
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <h1>Connect With Us</h1>
          <p className="subtitle">Tell us about your project. We will get back to you promptly.</p>
          {submitSuccess ? (
              <p>Thank you for your message!</p>
            ) : (
            <form onSubmit={handleSubmit}>
              <p>Name<span className="red">*</span></p>
              <p className="input-block">
                <input type="text" name="full_name" value={formValues.full_name} onChange={handleInputChange} />
              </p>
              <p>Email<span className="red">*</span></p>
              <p className="input-block">
                <input type="text" name="email" value={formValues.email} onChange={handleInputChange} />
              </p>
              <p>Describe your project briefly<span className="red">*</span></p>
              <p className="input-block">
                <textarea name="message" value={formValues.message} onChange={handleInputChange} />
              </p>
              <p><button type="submit">Send{submitting && <VscLoading className="rotating" style={{ marginLeft: '10px' }}/>}</button></p>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
