import React, { useEffect } from 'react';

import '../../App.css';
import ServiceSEO from '../../images/service_seo.png';
import ServiceUX from '../../images/service_ux.png';
import ServiceMaintenance from '../../images/service_maintenance.png';
import ServiceAnalytics from '../../images/service_analytics.png';
import ServiceHosting from '../../images/service_hosting.png';
import ServiceWeb from '../../images/service_web2.png';
import {
  Link
} from "react-router-dom";
import Header from '../../app/components/Header';
import SEO from '../../app/components/SEO';

interface Props {
  reportView?: Function;
}

const ServicesPage: React.FC<Props> = ({ reportView }) => {

  useEffect(() => {
    reportView && reportView(window.location.pathname);
  }, [window.location.pathname]);
  
  return (
    <div className="App">
      <SEO />
      <Header />
      <h1 className="services-title">
        OUR SERVICES
      </h1>
      <div className="services-grid">
        <Link to="service/ux-ui-design" style={{ textDecoration: 'none' }}>
          <div className="service">
            <img src={ServiceUX} />
            <div className="service-name">
              UX/UI<br />Design
            </div>
          </div>
        </Link>
        <Link to="service/search-engine-optimization" style={{ textDecoration: 'none' }}>
          <div className="service">
            <img src={ServiceSEO} />
            <div className="service-name">
              Search Engine<br />Optimization
            </div>
          </div>
        </Link>
        <Link to="service/web-development" style={{ textDecoration: 'none' }}>
          <div className="service">
            <img src={ServiceWeb} />
            <div className="service-name">
              Web<br />Development 
            </div>
          </div>
        </Link>
        <Link to="service/analytics" style={{ textDecoration: 'none' }}>
          <div className="service">
            <img src={ServiceAnalytics} />
            <div className="service-name">
              Google<br />Analytics
            </div>
          </div>
        </Link>
        <Link to="service/web-maintenance" style={{ textDecoration: 'none' }}>
          <div className="service">
            <img src={ServiceMaintenance} />
            <div className="service-name">
              Web<br />Maintenance
            </div>
          </div>
        </Link>
        <Link to="service/web-hosting" style={{ textDecoration: 'none' }}>
          <div className="service">
            <img src={ServiceHosting} />
            <div className="service-name">
              Web<br />Hosting
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ServicesPage;
