import React, { useCallback, useEffect, useState, useRef } from 'react';
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
  Link
} from "react-router-dom";
import ReactPageScroller from 'react-page-scroller';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Div100vh, { use100vh } from 'react-div-100vh';

import './App.css';
import slide1 from './images/slide1.jpg';
import slide2 from './images/slide2.jpg';
import slide3 from './images/slide3.jpg';
import slide4 from './images/slide4.jpg';
import logo from './images/LOGO_LINE.ai1.2.3.4.png';
import { FaQuestionCircle, FaBars, FaPencilAlt } from 'react-icons/fa';
import ServicesPage from './app/pages/ServicesPage';
import ContactUsPage from './app/pages/ContactUsPage';
import PackagesPage from './app/pages/PackagesPage';
import InfographicPage from './app/pages/InfographicPage';
import LoginPage from './app/pages/LoginPage';
import RegisterPage from './app/pages/RegisterPage';
import AdminPage from './app/pages/AdminPage';
import ProfilePage from './app/pages/ProfilePage';
import UxUiServicePage from './app/pages/services/UxUiServicePage';
import SeoServicePage from './app/pages/services/SeoServicePage';
import AnalyticsServicePage from './app/pages/services/AnalyticsServicePage';
import WebHostingServicePage from './app/pages/services/WebHostingServicePage';
import WebDevelopmentServicePage from './app/pages/services/WebDevelopmentServicePage';
import WebMaintenanceServicePage from './app/pages/services/WebMaintenanceServicePage';
import Infographic from './app/components/Infographic';
import PackagesComponent from './app/components/PackagesComponent';
import SEO from './app/components/SEO';
import Header from './app/components/Header';
import slide1Hq from './images/idea2.jpg';
import slide2Hq from './images/Open3.jpg';
import slide3Hq from './images/E_commerce2.jpg';
import slide5Hq from './images/who_we_are.jpg';
import ReactGA from 'react-ga';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

ReactGA.initialize('UA-201348370-1');

interface Props {
  reportView?: Function;
};

const HomePage: React.FC<Props> = ({ reportView }) => {
  const history = useHistory();
  const getScrollPage = () => {
    if (window.location.pathname === '/') {
      return 0;
    } else if (window.location.pathname === '/entrepreneurship') {
      return 1;
    } else if (window.location.pathname === '/e-commerce') {
      return 2;
    }  else if (window.location.pathname === '/who-we-are') {
      return 3;
    }
  };
  const [scrollPage, setScrollPage] = useState(getScrollPage());
  const [pageToScroll, setPageToScroll] = useState(undefined);
  const handleOnClick = useCallback(() => history.push('/services'), [history]);
  const handleGetStarted = useCallback(() => history.push('/contact-us'), [history]);
  useEffect(() => {
    console.log(window.location.pathname);
    reportView && reportView(window.location.pathname);
    if (window.location.pathname === '/') {
      setScrollPage(0);
    } else if (window.location.pathname === '/who-we-are') {
      setScrollPage(3);
    } else if (window.location.pathname === '/entrepreneurship') {
      setScrollPage(1);
    } else if (window.location.pathname === '/e-commerce') {
      setScrollPage(2);
    } 
  }, [window.location.pathname, setScrollPage, history]);

  const handlePageChange = (pageChange) => {
    console.log(pageChange);
    if (pageChange === 0) {
      history.push('/');
    } else if (pageChange === 1) {
      history.push('/entrepreneurship');
    } else if (pageChange === 2) {
      history.push('/e-commerce');
    } else if (pageChange === 3) {
      history.push('/who-we-are');
    } 
    const scroller = document.getElementsByClassName("main-scroller");
    if (scroller && scroller.length) {
      scroller[0].scrollTop = 0;
    }
  };

  const containerHeight = use100vh();
  console.log('scrollPage');
  console.log(scrollPage);
  return (
    <div className="App">
      <SEO />
      <Header setScrollPage={setScrollPage} />
      <ReactPageScroller customPageNumber={scrollPage} pageOnChange={handlePageChange} renderAllPagesOnFirstRender={true} className="main-scroller" containerHeight={containerHeight}>
        <Div100vh className="slide slide-1 lazyload" data-bg={slide1Hq}>
          <div className="slide-content">
            <div className="slide-content-block">
              <h1>WE ARE A WEB MARKETING AGENCY</h1>
              <p>
                That will help you turn your idea into reality, with websites custom-built to fit your budget.
              </p>
      
              <Button variant="contained" disableElevation color="secondary" style={{backgroundColor: '#000', borderRadius: '16px', padding: '4px 24px', textTransform: 'none', fontWeight: 600}} onClick={handleOnClick}>
                Learn More 
              </Button>
            </div>
          </div>
        </Div100vh>
        <Div100vh className="slide slide-2 lazyload" data-bg={slide2Hq}>
          <div className="slide-content">
            <h1>IF YOU ARE AN ENTREPRENEUR OR A SMALL BUSINESS OWNER</h1>
            <p style={{ marginTop: '4px', fontWeight: 300 }}>
              who is looking for a well-designed and functional website to attract your potential clients,&nbsp; 
              <Link to="/apply">we are here to help</Link>
            </p>
            <Button variant="contained" disableElevation color="secondary" style={{backgroundColor: '#000', borderRadius: '16px', padding: '4px 24px', textTransform: 'none', fontWeight: 600}} onClick={handleOnClick}>
              Learn More 
            </Button>
          </div>
        </Div100vh>
        <Div100vh className="slide slide-3 lazyload" data-bg={slide3Hq}>
          <div className="slide-content">
            <div className="slide-content-block">
              <h1>E-COMMERCE<br />WEB DESIGN SERVICE</h1>
              <p style={{ marginTop: '4px', fontWeight: 300 }}>Create your own professional online store.<br />Let us help you.</p>
              <Button variant="contained" disableElevation color="secondary" style={{backgroundColor: '#000', borderRadius: '16px', padding: '4px 24px', textTransform: 'none', fontWeight: 600}} onClick={handleOnClick}>
                Learn More 
              </Button>
            </div>
          </div>
        </Div100vh>
        <Div100vh className="slide slide-4 lazyload" data-bg={slide5Hq}>
          <div className="slide-content">
            <div className="slide-content-block">
              <p className="subheading">
                WHO WE ARE
              </p>
              <h1>
                BOSTON-TO-BANGKOK
              </h1>
              <p style={{ marginBottom: '24px' }}>
                BOS-BKK is an interactive and
                international web marketing agency.
                With more than 10 years experience in the
                web design field, we help our clients
                to create unique well-designed
                websites that fit your budget. We offer
                digital marketing solutions to our clients
                to help you grow your sales and
                achieve your business goals. 
                We provide customer support in English and Thai languages. 
                Get a free consultation today and make your project happen.
              </p>
              <Button 
                variant="contained" 
                disableElevation 
                color="secondary" 
                style={{
                  backgroundColor: '#fff', 
                  borderRadius: '16px', 
                  padding: '4px 24px', 
                  textTransform: 'none', 
                  fontWeight: 600, 
                  color: '#000' 
                }} 
                onClick={handleGetStarted}
              >
                Get a Free Consultation 
              </Button>
            </div>
          </div>
        </Div100vh>
      </ReactPageScroller>
    </div>
  );
};

const App: React.FC = () => {
  const reportView = (path) => {
    console.log(`sending pageview to GA: ${path}`)
    ReactGA.pageview(path);
  };

  return <Router>
    <Switch>
      <Route exact path="/">
        <HomePage reportView={reportView} />
      </Route>
      <Route exact path="/who-we-are">
        <HomePage reportView={reportView} />
      </Route>
      <Route exact path="/entrepreneurship">
        <HomePage reportView={reportView} />
      </Route>
      <Route exact path="/e-commerce">
        <HomePage reportView={reportView} />
      </Route>
      <Route exact path="/infographic">
        <InfographicPage reportView={reportView} />
      </Route>
      <Route exact path="/login">
        <LoginPage reportView={reportView} />
      </Route>
      <Route exact path="/register">
        <RegisterPage reportView={reportView} />
      </Route>
      <Route path="/services">
        <ServicesPage reportView={reportView} />
      </Route>
      <Route path="/contact-us">
        <ContactUsPage reportView={reportView} />
      </Route>
      <Route path="/packages">
        <PackagesPage reportView={reportView} />
      </Route>
      <Route path="/admin">
        <AdminPage />
      </Route>
      <Route path="/profile">
        <ProfilePage />
      </Route>
      <Route path="/service/web-development">
        <WebDevelopmentServicePage reportView={reportView} />
      </Route>
      <Route path="/service/web-maintenance">
        <WebMaintenanceServicePage reportView={reportView} />
      </Route>
      <Route path="/service/web-hosting">
        <WebHostingServicePage reportView={reportView} />
      </Route>
      <Route path="/service/search-engine-optimization">
        <SeoServicePage reportView={reportView} />
      </Route>
      <Route path="/service/ux-ui-design">
        <UxUiServicePage reportView={reportView} />
      </Route>
      <Route path="/service/analytics">
        <AnalyticsServicePage reportView={reportView} />
      </Route>
    </Switch>
  </Router>;
};

export default App;
